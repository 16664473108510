export class InvitationRequestViewModel {
  firstName: string;
  lastName: string;
  email: string;
  customerPortalInvitation: boolean;
  tenantName: string;
  tenantCode: string;
  userExists: boolean;
  role: 'Administrator' | 'User' | 'Driver';
  customerId?: number;
  preferredLanguage?: string;
}
